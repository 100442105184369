import React from 'react'
import './index.css'
import Home from './components/Home'

function App() {
  return (
    <Home></Home>
  )
}

export default App;
